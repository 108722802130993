
// import { baselightTheme } from "./theme/DefaultColors";
import { Themefunc } from './theme/DefaultColors';
import { CssBaseline, ThemeProvider } from '@mui/material';

import './assets/css/index.scss'
import Popup from './components/popup/popup';
// import { useEffect } from 'react';
// import axios from 'axios'; 
// import { FirstTimeWebSrn } from './hooks/FirstTimeWebSrn/FirstTimeWebSrn';
import Showclasses from './components/showclasses';
import Otp from './veiws/Website/authentication/otp/otp';
import  Home  from './veiws/Website/Home/Home';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function App() {
  const veri = useSelector((state) => state.Auth.permission);

  // const routing = useRoutes(Router);
  const theme = Themefunc();
  Showclasses()
  const [getToken, setGetToken] = useState(localStorage.getItem('User_Data'))
  console.log(getToken  )
  console.log(getToken != '' && getToken != null )

  useEffect(()=>{
    setGetToken(localStorage.getItem('User_Data'))
    console.log('useeFfect');     
  },[localStorage.getItem('User_Data')])
  return (
    <ThemeProvider theme={theme}>
      {/* <FirstTimeWebSrn /> */}
      <Popup />
      { getToken != '' && getToken != undefined && getToken != null ? 
      <Home />
      :
        <Otp />
      }
    <CssBaseline />
    {/* {routing} */}

  </ThemeProvider>
  )
}

export default App;
