import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
// import { CustomTable } from '../../../components/table/SimpleTable'
import  CustomTable  from '../../../components/table/SimpleTable'
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, useMediaQuery } from '@mui/material'
import StudentReportComponent from '../../../components/report/student';
import { Loader } from "../../../components/loader/loader";
import { useDispatch, useSelector } from 'react-redux';

const Home = (props) => {
    const dispatch = useDispatch();
    const userToken = JSON.parse(localStorage.getItem('User_Data'))?.token || undefined;
    const parameter = JSON.parse(localStorage.getItem('User_Data'))?.Descript || undefined;
    // const url = useSelector((state) => state.Api);
    const url = `${localStorage.getItem('server')}/apk/showFess`;
    console.log(parameter)
    const [data , setData ] = useState()

    useEffect(()=>{
        dispatch({ type: "SHOW_LOADER", Seconds: "1" });
        axios.post(url,{parameter},{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            dispatch({ type: "SHOW_LOADER", Seconds: "0" });
            if (res.status === 200) {
              console.log(res.data);
              setData(res.data)
            }
          })
          .catch((err) => {
            console.error(err); // Log the error response for debugging
          });

    },[])
  return (
    <>
    <Box >
      <Box mt={1} lg={12} justifyContent={'center'} justifyItems={'center'} display={'flex'}> <img width={'30%'} height={'30%'} style={{maxWidth:'150px'}} src={`${parameter.imageUrl}/Logo.png`} /> </Box>
    </Box>
      {
        data && data?.length > 0 ?
          <Box mt={2} >
            <Grid item lg={12} ><Box className='announce'>{data[0]?.result?.announcement[0]?.Message}</Box></Grid>
          </Box>
          : null
      }

      {
        data && (
          data.map((item, ind) => (
            <Box display={'flex'} justifyContent={'center'} justifyItems={'center'} alignItems={'center'} key={ind}>
              <StudentReportComponent data={item.result} />
            </Box>
          )))
      }
    </>
  )
}

Home.propTypes = {}

export default Home